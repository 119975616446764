<template>
  <signup-layout>
    <router-view />
  </signup-layout>
</template>

<script>
import SignupLayout from '@/layouts/SignupLayout'

export default {
  components: { SignupLayout },

  mounted() {
    let organisationQuerySize = 0
    if (this.$route.query.size) {
      organisationQuerySize = Number(this.$route.query.size)
    }
    window.localStorage.setItem('organisation_user_size', organisationQuerySize)
  },
}
</script>
